<template>
  <section class="container mt-20">
    <h1 @click="n">Нажми на меня</h1>
  </section>
</template>

<script setup>
import { notify } from '@/logic/functions'

function n() {
  const visibility = 3000
  notify({ emoji: '💎', title: 'Событие', text: 'Пример уведомления', visibility })
  notify({ emoji: '🔥', title: 'Событие', text: 'Пример уведомления', visibility })
  notify({ svg: 'icons/telegram', title: 'Событие', text: 'Пример уведомления', visibility })
  notify({ lottie: 'ton', title: 'Событие', text: 'Пример уведомления', visibility })
  notify({ lottie: 'fire', title: 'Событие', text: 'Пример уведомления', visibility })
  notify({ lottie: 'wait', title: 'Событие', text: 'Пример уведомления', visibility })
  notify({ image: 'icons/telegram.svg', title: 'Событие', text: 'Пример уведомления', visibility })
}
</script>

<style lang="sass" scoped>
h1
  cursor: pointer
</style>